import _isEqual from "lodash/isEqual"
import dynamic from "next/dynamic"
import React from "react"
import { Provider as ReduxProvider } from "react-redux"
import { CategoryProvider } from "../category/CategoriesContexts"
import { CommerceToolsClientProvider } from "../commercetools/CommerceToolsClientProvider"
import { GoogleMapsProvider } from "../components/Maps"
import { FeatureFlagsI, FeatureFlags, FlagProvider } from "../FeatureFlags"
import { RegionState } from "../i18n/RegionState"
import { StoreProvider } from "../i18n/StoreProvider"
import { CheckoutConfigProvider } from "../layout/CheckoutConfigProvider"
import { MessageBarProvider } from "../layout/MessageBarProvider"
import { NavigationProvider } from "../layout/NavigationProvider"
import { GoogleRecaptchaWrapper } from "../recaptcha/GoogleRecaptchaWrapper"
import { SearchQueryProvider } from "../search/SearchQueryContext"
import State from "../State"
import { ExchangeRatesProvider } from "../tagmanager/ExchangeRatesContext"
import {
  LanguageProvider,
  userAcceptLanguageToSupportedLanguage
} from "../UserAcceptedLanguage"
import { GlobalProps } from "./GlobalProps"

const I18NextProvider = dynamic(
  import("../i18n/I18NextProvider").then(it => it.I18NextProvider)
)

interface GlobalAppProps {
  children: React.ReactNode
  pageProps: GlobalProps
  acceptLanguage: string
}

export const GlobalAppProviders = (props: GlobalAppProps) => {
  const { children, pageProps, acceptLanguage } = props
  const store = State
  const features = pageProps.features || FeatureFlags.features
  const flags: FeatureFlagsI = { features }

  if (!_isEqual(store.getState().regionState.region, pageProps.region)) {
    store.dispatch(RegionState.actions.setRegion(pageProps.region))
  }

  return (
    <ReduxProvider store={store}>
      <FlagProvider flags={flags}>
        <LanguageProvider
          language={userAcceptLanguageToSupportedLanguage(acceptLanguage)}
        >
          <StoreProvider region={pageProps.region} store={pageProps.store}>
            <CheckoutConfigProvider config={pageProps.checkoutConfiguration!}>
              <MessageBarProvider messages={pageProps.messages}>
                <NavigationProvider
                  navigation={pageProps.navigation || undefined}
                  navigationTrays={pageProps.navigationTrays || undefined}
                  navigationLinkedDocuments={
                    pageProps.navigationLinkedDocuments
                  }
                >
                  <SearchQueryProvider query={pageProps.searchQuery}>
                    <CategoryProvider {...pageProps}>
                      <GoogleMapsProvider>
                        <GoogleRecaptchaWrapper>
                          <CommerceToolsClientProvider>
                            <ExchangeRatesProvider
                              rates={pageProps.exchangeRates}
                            >
                              <I18NextProvider>{children}</I18NextProvider>
                            </ExchangeRatesProvider>
                          </CommerceToolsClientProvider>
                        </GoogleRecaptchaWrapper>
                      </GoogleMapsProvider>
                    </CategoryProvider>
                  </SearchQueryProvider>
                </NavigationProvider>
              </MessageBarProvider>
            </CheckoutConfigProvider>
          </StoreProvider>
        </LanguageProvider>
      </FlagProvider>
    </ReduxProvider>
  )
}

export default GlobalAppProviders
